/* Buttons */
.btn {
	font-size: 0.8rem;
	padding: 0.533rem 0.933rem;
	cursor: pointer;
	font-weight: 600;

	&.btn-xs {
		font-size: 0.7rem;
		padding: 0.2rem 0.5rem;
	}

	&.btn-sm {
		font-size: 0.8rem;
		padding: 0.3rem 0.65rem;
	}

	&.btn-lg {
		font-size: 1rem;
		padding: 0.5rem 1rem;
	}

	&.btn-xl {
		font-size: 1rem;
		padding: 0.8rem 2rem;
	}

	// Paddings
	&.btn-px-1 {
		padding-left: .35rem;
		padding-right: .35rem;
	}

	&.btn-px-2 {
		padding-left: .75rem;
		padding-right: .75rem;
	}

	&.btn-px-3 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	&.btn-px-4 {
		padding-left: 1.75rem;
		padding-right: 1.75rem;
	}

	&.btn-px-5 {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	&.btn-py-1 {
		padding-top: .35rem;
		padding-bottom: .35rem;
	}

	&.btn-py-2 {
		padding-top: .75rem;
		padding-bottom: .75rem;
	}

	&.btn-py-3 {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	&.btn-py-4 {
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
	}

	&.btn-py-5 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

html {
	body {
		&, &.body {
			.btn.focus,
			.btn:focus,
			.btn.active,
			.btn:active {
				box-shadow: none !important;
			}
		}
	}
}

/* Dropdown Buttons */
.btn + .dropdown-menu {
	.dropdown-item {
		font-size: 0.8rem;
	}
}

/* Default */
.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
	box-shadow: none !important;
	
	&:hover, &:active, &:focus  {
		color: #333;
		background-color: #e6e6e6;
		border-color: #adadad;
	}
}

/* Outline */
.btn-outline {
	border-width: 2px;
	padding: 0.483rem 0.933rem;

	&.btn-xs {
		padding: 0.15rem 0.5rem;
	}

	&.btn-sm {
		padding: 0.25rem 0.65rem;
	}

	&.btn-lg {
		padding: 0.45rem 1rem;
	}

	&.btn-xl {
		padding: 0.75rem 2rem;
	}

	&.btn-outline-thin {
		border-width: 1px;
	}
}

/* Modern */
.btn-modern {
    text-transform: uppercase;
	font-size: .8em;
	padding: 0.8rem 1.5rem;
	font-weight: 600;
}

/* Rounded */
.btn-rounded {
	border-radius: 35px;
}

/* Rounded */
.btn-rounded-icon {
	border-radius: 50px;
	padding: 0;
	width: 50px;
	height: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	&[data-hash] {
		> i {
			pointer-events: none;
		}
	}
}

/* 3d */
.btn-3d {
	border-bottom-width: 3px;
}

/* Arrow */
.btn-with-arrow,
.btn-with-arrow-solid, {
	&:not(.btn-with-arrow-solid) {
		background-color: transparent;
	}
	font-weight: 600;
	
	span {
		background-color: #ccc;
		display: inline-block;
		height: 25px;
		line-height: 24px;
		width: 24px;
		border-radius: 50%;
		margin-left: 10px;
		transition: all 0.1s linear;

	}

	i { 
		color: #FFF;
		font-size: 8px;
		top: -1px;
		position: relative;
	}

	&:hover {
		span {
			transform: translateX(5px);
		}
	}

	&.btn-lg, &.btn-xl {
		span {
			line-height: 23px;
		}
	}

	&.btn-xs {
		span {
			line-height: 25px;
		}
	}
}

/* Swap */
.btn-swap-1 {
	position: relative;
	> * {
	    transition: cubic-bezier(.55,0,.1,1) all 300ms;
	    white-space: nowrap;
	}
	> :nth-child(1) {
		max-width: 100%;
		text-align: center;
		top: -webkit-calc(50% - 10px);
		top: calc(50% - 10px);
		left: 50%;
		top: 50%;
		margin: 0;
		transform: translate(-50%,-50%);
		position: absolute;
	}
	> :nth-child(2) {
		display: inline-block;
		transform: translateY(120%);
		opacity: 0;
	}
	&:hover {
		> :nth-child(1) {
			transform: translate(-50%,-120%);
			opacity: 0;
		}
		> :nth-child(2) {
			transform: none;
			opacity: 1;
		}
	}
}

@keyframes btnArrowEffect1 {
	0% {
		transform: translateX(0);
	}
	51% {
		transform: translateX(20px);
		opacity: 1;
	}
	52% {
		opacity: 0;
		transform: translateX(-20px);
	}
	53% {
		opacity: 0;
	}
	54% {
		opacity: 1;
	}
	100% {
		transform: translateX(0);
	}
}

.btn-arrow-effect-1 {
	&:hover {
		> :nth-child(1) {
			animation: btnArrowEffect1 ease-out .3s;
		}
	}
}

/* Link Effect */
.btn-link-underline-1 {
	&:before {
		height: 1px !important;
	}
}

.btn-link-effect-1 {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 0;
		height: 2px;
		background: $color-dark;
		transition: width 0.25s ease-out;
	}

	&:hover,
	&:active {
		&:before {
			width: 100%;
		}
	}

}

.btn-link-effect-2 {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		right: 0;
		bottom: -2px;
		width: 0;
		height: 2px;
		background: $color-dark;
		transition: width 0.25s ease-out;
	}

	&:hover,
	&:active {
		&:before {
			width: 100%;
		}
	}

}

.btn-link-effect-3 {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: -2px;
		width: 0;
		height: 2px;
		background: $color-dark;
		transition: width 0.3s ease 0s, left 0.3s ease 0s;
	}

	&:hover,
	&:active {
		&:before {
			width: 100%;
			left: 0;
		}
	}

}

.btn-link-effect-4 {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 100%;
		height: 2px;
		background: $color-dark;
		transform: scaleX(0);
		transform-origin: bottom right;
		transition: transform .3s;
	}

	&:hover,
	&:active {
		&:before {
			transform-origin: bottom left;
			transform: scaleX(1);
		}
	}

}

.btn-link-effect-5 {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
		background: $color-dark;
		transition: height 0.2s ease;
	}

	&:hover,
	&:active {
		&:before {
			height: 100%;
		}
	}

}

/* Icons  */
.btn-icon i {
	margin-#{$right}: 10px;
}

.btn-icon-right i {
	margin-#{$right}: 0;
	margin-#{$left}: 10px;
}

/* Dropdown Button */
.btn {
	&.dropdown-toggle {
		&:after {
			margin-left: .155em;
			vertical-align: .155em;
		}
	}
}

/* Colors */
@each $state in $states {
	$color: nth($state, 2);
	$textColor: nth($state, 3);

	.btn-#{nth($state,1)} {
		background-color: $color;
		border-color: $color $color darken($color, 10%);
		color: $textColor;

		&:hover,
		&.hover {
			background-color: lighten($color, 7.5%);
			border-color: lighten($color, 10%) lighten($color, 10%) $color;
			color: $textColor;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba($color, .5);
		}

		&.disabled,
		&:disabled {
			background-color: $color;
			border-color: $color $color darken($color, 10%);
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			background-color: darken($color, 7.5%);
			background-image: none;
			border-color: darken($color, 10%) darken($color, 10%) darken($color, 20%);
		}
	}

	.btn-outline.btn-#{nth($state,1)} {
		color: $color;
		background-color: transparent;
		background-image: none;
		border-color: $color;

		&:hover,
		&.hover {
			color: $textColor;
			background-color: $color;
			border-color: $color;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba($color, .5);
		}

		&.disabled,
		&:disabled {
			color: $color;
			background-color: transparent;
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			color: $textColor;
			background-color: $color;
			border-color: $color;
		}
	}
}

/* Button Light */
html body {
	.btn-light,
	.btn-outline.btn-light {
		border-color: darken(#FFF, 15%) darken(#FFF, 15%) darken(#FFF, 25%);
		color: $color-font-default;

		&.btn-outline-light-opacity-2 {
			border-color: rgba(255, 255, 255, 0.2);
		}

		&:hover,
		&.hover {
			background-color: darken(#FFF, 5%);
			border-color: darken(#FFF, 10%) darken(#FFF, 10%) darken(#FFF, 20%);
			color: $color-font-default;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba(darken(#FFF, 25%), .5);
		}

		&.disabled,
		&:disabled {
			border-color: darken(#FFF, 25%) darken(#FFF, 25%) darken(#FFF, 25%);
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			border-color: darken(#FFF, 25%) darken(#FFF, 25%) darken(#FFF, 25%);
		}
	}

	.btn-light-2,
	.btn-outline.btn-light-2 {
		border-color: #FFF;
		color: #FFF;

		&:hover,
		&.hover {
			background-color: #FFF;
			border-color: #FFF;
			color: #0e0e0e;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba(darken(#FFF, 30%), .5);
		}

		&.disabled,
		&:disabled {
			border-color: darken(#FFF, 30%) darken(#FFF, 30%) darken(#FFF, 30%);
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			border-color: #FFF;
		}
	}
}

html[dir="rtl"] {
	.input-group .form-control:not(:first-child), 
	.input-group-addon:not(:first-child), 
	.input-group-btn:not(:first-child)>.btn, 
	.input-group-btn:not(:first-child)>.btn-group>.btn, 
	.input-group-btn:not(:first-child)>.dropdown-toggle, 
	.input-group-btn:not(:last-child)>.btn-group:not(:first-child)>.btn, 
	.input-group-btn:not(:last-child)>.btn:not(:first-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
}

/* Hamburguer */
.hamburguer-btn {
	background: transparent;
	outline: 0 !important;
	border: none;
	width: 30px;
	height: 30px;
	padding: 0;
	margin: 15px;
	display: inline-block;
	position: relative;
	cursor: pointer;

	&.hamburguer-btn-side-header {
		position: absolute;
		top: 15px;
		right: 8px;
	}

	&.hamburguer-btn-side-header-overlay {
		right: 30px;
		z-index: 9999;
	}

	.hamburguer {
		pointer-events: none;
		span {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 1px;
			transition: ease width 300ms;
			transform: translateY(-50%);
			background: #333;
			&:nth-child(1) {
				top: 30%;
			}
			&:nth-child(2) {
				transition-delay: 100ms;
			}
			&:nth-child(3) {
				top: 70%;
				transition-delay: 200ms;
			}
		}
	}

	.close {
		opacity: 0;
		pointer-events: none;
		transition: ease all 300ms;
		span {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 0;
			height: 1px;
			background: #333;
			transition: ease all 300ms;
			transform-origin: 50% 0;
			&:nth-child(1) {
				transform: translateX(-50%) rotate(45deg);
			}
			&:nth-child(2) {
				transform: translateX(-50%) rotate(-45deg);
			}
		}
	}

	&.active {
		.hamburguer {
			opacity: 0;
			span {
				width: 0;
			}
		}
		.close {
			opacity: 1;
			transition: ease all 300ms;
			span {
				width: 80%;
				transition: ease all 300ms;
				&:nth-child(2) {
					transition-delay: 300ms;
				}
			}
		}
	}

	&.hamburguer-btn-light {
		.hamburguer span,
		.close span {
			background: #FFF;
		}
	}
}

html.overflow-hidden {
	.hamburguer-btn {
		&.hamburguer-btn-side-header-overlay {
			right: 13px;
		}
	}
}

/* Hamburguer Sticky Colors */
html.sticky-header-active,
.pin-wrapper .sticky-active:not([class*="sticky-wrapper-effect"]),
.pin-wrapper .sticky-effect-active {
	.hamburguer-btn {
		&.hamburguer-btn-sticky-light {
			.hamburguer span,
			.close span {
				background: #FFF;
			}
		}
		&.hamburguer-btn-sticky-dark {
			.hamburguer span,
			.close span {
				background: $color-dark;
			}
		}
	}
}

/* Badge */
.btn-badge {
	position: relative;
	.badge {
		top: -0.5rem;
		right: -0.5rem;
		position: absolute;
	}
}

/* Gradient */
.btn-gradient:not(.btn-outline) {
	border: 0;

	&:hover,
	&.hover {
		border: 0;
	}

	&:focus,
	&.focus {
		border: 0;
	}

	&.disabled,
	&:disabled {
		border: 0;
	}

	&:active,
	&.active {
		border: 0;
	}
}

.btn-gradient.btn-outline {
	border: 2px solid transparent;
	background: transparent;
	border-image-slice: 1 !important;

	&:hover,
	&.hover {
		border: 2px solid transparent;
	}

	&.disabled,
	&:disabled {
		border: 2px solid transparent;
	}

	&:active,
	&.active {
		border: 2px solid transparent;
	}
}

/* Play Button Size */
.play-button-sm {
	width: 35px;
	height: 35px;
}

.play-button-md {
	width: 60px;
	height: 60px;
}

.play-button-lg {
	width: 90px;
	height: 90px;
}

/* Curcle Buttons */
.btn-circle {
	width: 30px;
	height: 30px;
	text-align: center;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.428571429;
	border-radius: 15px !important;

	&.btn-lg {
		width: 50px;
		height: 50px;
		padding: 10px 16px;
		font-size: 18px;
		line-height: 1.6;
		border-radius: 25px !important;
	}

	&.btn-xl {
		width: 70px;
		height: 70px;
		padding: 10px 16px;
		font-size: 24px;
		line-height: 2;
		border-radius: 35px !important;
	}
}

/* Effect */
.btn {

	// Effect 1
	&.btn-effect-1 {
		transition: 0.25s;
		&:hover, &:focus {
			box-shadow: inset 0 0 0 2em var(--hover);
		}
	}

	// Effect 2
	&.btn-effect-2 {
		transition: 0.25s;
		&:hover, &:focus {
			animation: btn-pulse 1s;
			box-shadow: 0 0 0 2em rgba(#fff,0);
		}
	}

	// Effect 3
	&.btn-effect-3 {
		transition: 0.25s;
		&:hover, &:focus {
			box-shadow: 
				inset -3.5em 0 0 0 var(--hover),
				inset 3.5em 0 0 0 var(--hover);  
		}
	}

	// Effect 4
	&.btn-effect-4 {
		transition: 0.25s;
		&:hover, &:focus {
			box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
			transform: translateY(-0.25em);
		}
	}

	// Effect 5
	&.btn-effect-5 {
		transition: 0.25s;
		&:hover, &:focus {
			box-shadow: inset 0 -3.25em 0 0 var(--hover);
		}
	}

	// Effect 6
	&.btn-effect-6 {
		transition: 0.25s;
		&:hover, &:focus {
			box-shadow: inset 6.5em 0 0 0 var(--hover);
		}
	}

	// Effect 7
	&.btn-effect-7 {
		position: relative;
		&:before {
			background: var(--hover);
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			margin: auto;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 0;
			transition: transform .2s cubic-bezier(.40,.30,.35,.90)0s;
			transform: scaleX(0);
			transform-origin: right center;
		}
		&:hover, &:focus {
			&:before {
			    transform: scale(1);
			    transform-origin: left center;
			}
		}
		span {
			position: relative;
			z-index: 1;
		}
	}

}

@keyframes btn-pulse {
	0% { box-shadow: 0 0 0 0 var(--hover); }
}