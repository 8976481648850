/* Dropdowns */
.dropdown {

	&.dropdown-style-1 {
		.dropdown-toggle {
		    display: flex;
		    align-items: center;
		    padding: 1rem 1.1rem;
		    background: #FFF;
		    border-radius: 0;
		    border-right: 1px solid #ededed;
		    min-width: 280px;
		    font-weight: bold;
		    text-decoration: none;
			&:after {
				content: '\f107';
			    position: absolute;
			    right: 1.1rem;
			    top: 50%;
			    font-family: 'Font Awesome 6 Sharp';
			    font-weight: bold;
			    transform: translate3d(0, -50%, 0);
			    border: 0;
			    margin: 0;
			    width: 10px;
			    height: 10px;
			    line-height: 10px;
			}
		    > i {
		    	display: none;
		    }
		}

		.dropdown-menu {
		    width: 100%;
		    border-radius: 0;
		    margin-top: -1px;
		    border-color: #ededed;
		    .dropdown-item {
		    	font-size: 14px;
		    	padding-right: 1.1rem;
		    	padding-left: 1.1rem;
		    }
		}
	}#head

	&.dropdown-corner-left-fix {
		.dropdown-menu {
			margin-#{$left}: -5px; 
		}
	}

}

.dropdown-menu {
	&.dropdown-menu-arrow-centered {
		&:before {
			right: auto;
			left: 50%;
		    transform: translate3d(-50%, -6px, 0);
		}
	}
	&.dropdown-menu-arrow-centered-style-2 {
		border: 0;
		margin-top: -2px;
		margin-left: -2px;
		padding: 0;
		box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.05);
		background: #FFF;
		padding: 3px;
		min-width: 180px;
		z-index: 10000;
		margin-right: 0;
		margin-top: 1px;
		transition: opacity .2s ease-out;
		border-radius: 0;
	}
}

.dropdown-item:not(.btn):hover {
	text-decoration: none;
}

.dropdown-mega .dropdown-mega-sub-nav>li>a {
	width:auto
}

.section-underline {
	border-bottom:1px solid #555;
	margin-bottom:10px;
}