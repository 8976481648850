// TYPOGRAPHY
// ---------------------------------------------------------------
$font-primary: "Poppins", Arial, sans-serif;
$font-secondary: "Shadows Into Light", cursive;
$font-tertiary: "Oswald", sans-serif;

$body-font-size: 14;
$root-font-size: 10;
$body-line-height: 26;

// COLORS
// ---------------------------------------------------------------
$color-font-default: #212529;

$color-default: #f2f2f2;

$color-muted: #999;

$color-light: #FFF;

$color-grey: #EDEDED;

$color-dark: #212529;
$color-dark-inverse: #FFF;
$color-dark-1: $color-dark;
$color-dark-2: lighten($color-dark-1, 2%);
$color-dark-3: lighten($color-dark-1, 5%);
$color-dark-4: lighten($color-dark-1, 8%);
$color-dark-5: lighten($color-dark-1, 3%);
$color-darken-1: darken($color-dark-1, 2%);

$color-primary: #CCC;
$color-primary-inverse: #FFF;

$color-secondary: #e4002c;
$color-secondary-inverse: #FFF;

$color-tertiary: #8a3636;
$color-tertiary-inverse: #FFF;

$color-quaternary: $color-dark-4;
$color-quaternary-inverse: #FFF;

$color-success: #28a745;
$color-success-inverse: #FFF;

$color-warning: #ffc107;
$color-warning-inverse: #FFF;

$color-danger: #dc3545;
$color-danger-inverse: #FFF;

$color-info: #17a2b8;
$color-info-inverse: #FFF;

// States List
$states: (primary $color-primary $color-primary-inverse) (success $color-success $color-success-inverse) (warning $color-warning $color-warning-inverse) (danger $color-danger $color-danger-inverse) (info $color-info $color-info-inverse) (dark $color-dark $color-dark-inverse);

// Light Version
$light-bg: #FFF;

// Dark Version
$dark-bg: $color-dark;
$dark-default-text: #808697;

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;